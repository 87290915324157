// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.infinite-cards {
    height: 200px;
    width: 100%;
    display: grid;
    place-items: center center;
    .card {
      position: fixed;
      top: 5%;
      bottom: 5%;
      left: auto;
      right: auto;
      width: 90%;
      max-width: 400px;
      border-radius: 15px;
      color: #fff;
      display: grid;
      place-items: center center;
      padding: 10px;
      p {
        text-align: center;
        font-size: 28px;
        font-weight: 700;
      }
    }
  }`, "",{"version":3,"sources":["webpack://./src/swipe.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,WAAW;IACX,aAAa;IACb,0BAA0B;IAC1B;MACE,eAAe;MACf,OAAO;MACP,UAAU;MACV,UAAU;MACV,WAAW;MACX,UAAU;MACV,gBAAgB;MAChB,mBAAmB;MACnB,WAAW;MACX,aAAa;MACb,0BAA0B;MAC1B,aAAa;MACb;QACE,kBAAkB;QAClB,eAAe;QACf,gBAAgB;MAClB;IACF;EACF","sourcesContent":[".infinite-cards {\n    height: 200px;\n    width: 100%;\n    display: grid;\n    place-items: center center;\n    .card {\n      position: fixed;\n      top: 5%;\n      bottom: 5%;\n      left: auto;\n      right: auto;\n      width: 90%;\n      max-width: 400px;\n      border-radius: 15px;\n      color: #fff;\n      display: grid;\n      place-items: center center;\n      padding: 10px;\n      p {\n        text-align: center;\n        font-size: 28px;\n        font-weight: 700;\n      }\n    }\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
